import { neutral } from "style/color";
import {
  AddMoneyPage,
  InvestmentsBuyRequestPagePath,
  MyGrantVestmentRequestPage,
  CharityGrantRequestPagePath,
} from "constants/pages";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { moneyFormat } from "utils/money-format";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { ReactComponent as GrantIcon } from "./../../assets/Icons/Grant.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { ReactComponent as TransferOutgoingIcon } from "./../../assets/Icons/transfer-outgoing.svg";
import { AuthContext } from "providers/userProvider";
import { useContext } from "react";
import { AuthData } from "types/auth";
import { USER_ROLE } from "types/account";
import { USER_STATUS } from "types/fund-account-invitation";
interface BalanceBoxProps {
  name: string;
  total: number;
  available?: number;
  children?: ReactJSXElement;
  dashboardStyle?: boolean;
  showRequestsButtons?: boolean;
}
export default function BalanceBox(props: BalanceBoxProps): ReactJSXElement {
  const {
    name,
    total,
    available,
    children,
    dashboardStyle = false,
    showRequestsButtons: requestsButtons = false,
  } = props;
  const navigate = useNavigate();
  const { userData, userRole } = useContext<AuthData>(AuthContext);

  return (
    <>
      <Box sx={{ mx: dashboardStyle ? 0 : 3 }}>
        <Grid sx={{ mb: 2, mt: 2 }} container spacing={2}>
          <Grid item xs={12} sm={12}>
            {dashboardStyle ? (
              <Typography variant="h1">{name} </Typography>
            ) : (
              <Typography variant="h3">{name}</Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4} sx={{ p: 2, pt: "37px", display: "flex" }}>
            <Grid sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="smallCopy">TOTAL VALUE</Typography>

              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: 4,
                }}
              >
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: "40px",
                      lineHeight: "44px",
                    }}
                  >
                    ${moneyFormat(total)}
                  </Typography>
                </Grid>
                {available !== undefined && (
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}
                  >
                    <Typography
                      variant="smallCopy"
                      sx={{ color: neutral[600] }}
                    >
                      AVAILABLE BALANCE
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "18px",
                        lineHeight: "19.94px",
                      }}
                    >
                      ${moneyFormat(available)}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {requestsButtons && (
            <Grid
              item
              xs={12}
              sm={8}
              textAlign={"right"}
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "end",
                my: 1,
              }}
            >
              <Grid justifyContent={"flex-end"} container spacing={2}>
                <Grid item>
                  <Button
                    onClick={() => navigate(AddMoneyPage)}
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={
                      userRole === USER_ROLE.VIEWER ||
                      userData?.user?.status === USER_STATUS.PENDING
                    }
                  >
                    Add cash or securities
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => navigate(InvestmentsBuyRequestPagePath)}
                    startIcon={<TransferOutgoingIcon />}
                    variant="outlined"
                    color="primary"
                    size="large"
                    disabled={
                      userRole === USER_ROLE.VIEWER ||
                      userData?.user?.status === USER_STATUS.PENDING
                    }
                  >
                    Invest
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => navigate(MyGrantVestmentRequestPage)}
                    startIcon={<VisibilityOutlinedIcon />}
                    variant="outlined"
                    color="primary"
                    size="large"
                  >
                    Browse GrantVestments
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => navigate(CharityGrantRequestPagePath)}
                    startIcon={<GrantIcon />}
                    variant="outlined"
                    color="primary"
                    size="large"
                    disabled={
                      userRole === USER_ROLE.VIEWER ||
                      userData?.user?.status === USER_STATUS.PENDING
                    }
                  >
                    Grant
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          {children && (
            <Grid
              item
              xs={12}
              sm={8}
              my={2}
              textAlign={"right"}
              sx={{ display: "flex", gap: 2 }}
            >
              {children}
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}
